import {Outlet, Link} from "react-router-dom"
import React, {useState} from 'react'
import Login from "./login/views/Login";

const Layout = () => {
    const [openLogin, setOpenLogin] = useState(false)
    return (
        <>
            <nav className="bg-white border-gray-200 dark:bg-zinc-900">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
                    <Link to='/' className="font-semibold text-xl tracking-tight text-white">FOOTBALL</Link>
                    <div>
                        <button
                            className='bg-zinc-900 hover:bg-white hover:text-zinc-900 text-white py-1.5 px-3 rounded border border-white lg:flex-grow'
                            type='button'
                            onClick={() => setOpenLogin(true)}
                        >
                            Log In
                        </button>
                        {openLogin ?
                            <Login
                                openLogin={openLogin}
                                setOpenLogin={setOpenLogin}
                            />
                            : null}
                    </div>
                </div>
            </nav>
            <Outlet/>
        </>
    )
}

export default Layout